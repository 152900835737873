import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "../context/AuthContext";
import RequestHistory from "./requestsHistory";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const Requests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [searchQuery, setSearchQuery] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const handleshowHistory = () => setShowHistory(true);
  const handleCloseHistory = () => setShowHistory(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/inventory/requests/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
          action: "Pending",
        },
      });

      if (response.status === 200) {
        setData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.username]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleAccept = async (id) => {
    console.log(`Accepted: ${id}`);
    await handleSubmitA(id);
    fetchData();
  };

  const handleRefuse = async (id) => {
    console.log(`Refused: ${id}`);
    await handleSubmitR(id);
    fetchData();
  };

  const handleSubmitA = async (id) => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/requests/`,
        {
          Action: "Accept",
          request_id: id,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitR = async (id) => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/requests/`,
        {
          Action: "Refuse",
          request_id: id,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div
      className="container mt-5"
      style={{
        overflow: "auto",
        height: "80vh",
      }}
    >
      <div className="mb-4 d-flex">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="form-control"
        />
        <button className="add-button" onClick={handleshowHistory}>
          History
        </button>
      </div>
      {!loading && filteredData.length > 0 && (
        <div className="row">
          {filteredData.map((item) => (
            <div className="col-12 mb-4" key={item.request_id}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    <b>Initiator: </b>
                    {item.initiator}
                  </h5>
                  <h5 className="card-title">
                    <b>Date: </b>
                    {formatDate(item.date)}
                  </h5>

                  <p className="card-text">{item.description}</p>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-success"
                      onClick={() => handleAccept(item.request_id)}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleRefuse(item.request_id)}
                    >
                      Refuse
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Modal
            show={showHistory}
            onHide={handleCloseHistory}
            className="modal-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Request History</Modal.Title>
            </Modal.Header>
            <div className="Subform">
              <RequestHistory />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Requests;
