import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";

function Statement(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [entries, setEntries] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/statement/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            client_id: props.client_id,
          },
        });

        if (response.status === 200 && isMounted) {
          setEntries(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getOrders();

    return () => {
      isMounted = false;
    };
  }, [props.client_id, user.username, authTokens.access]);

  return (
    <div className="testi">
      <div className="mod">
        <h4>All Records</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Type</th>
              <th>Total Amount</th>
              <th>Discount</th>
              <th>Status</th>
              <th>Paid Amount USD</th>
              <th>Paid Amount LBP</th>
              <th>Discount USD</th>
              <th>Discount LBP</th>
              <th>Returned USD</th>
              <th>Returned LBP</th>
              <th>Exchange Rate</th>
              <th>Balance Record</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.date}</td>
                <td>{entry.description || "N/A"}</td>
                <td>{entry.type}</td>
                <td>{entry.total_amount || "N/A"}</td>
                <td>{entry.discount || "N/A"}</td>
                <td>{entry.status || "N/A"}</td>
                <td>{entry.paid_amount_usd || "N/A"}</td>
                <td>{entry.paid_amount_lbp || "N/A"}</td>
                <td>{entry.discount_usd || "N/A"}</td>
                <td>{entry.discount_lbp || "N/A"}</td>
                <td>{entry.returned_usd || "N/A"}</td>
                <td>{entry.returned_lbp || "N/A"}</td>
                <td>{entry.exchange_rate || "N/A"}</td>
                <td>{entry.balance_record || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Statement;
