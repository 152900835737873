import React, { useState, useEffect, useContext, useCallback } from "react";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import Select from "react-select";

function InvoiceOrder(props) {
  const [message, setMessage] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [users, setUsers] = useState([]);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0);
  const [totalWithoutDiscount, setTotalWithoutDiscount] = useState(0);
  const [cat, setCat] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [Drawers, setDrawers] = useState([]);
  const [username, setUsername] = useState("");

  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedDrawer, setSelectedDrawer] = useState("");

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const history = useHistory();

  const getClient = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discount/add/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, client_id: props.client_id },
        }
      );

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [authTokens.access, user.username, props.client_id]);
  useEffect(() => {
    let isMounted = true;

    const getOrders = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/order/details/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              order_id: props.order_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setCat(response.data);
          const initialTotal = calculateTotal(response.data);
          setTotalWithoutDiscount(initialTotal);
          setTotalWithDiscount(initialTotal);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getWarehouses = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/warehouses/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setWarehouses(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getDrawers = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/exchange/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setDrawers(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getOrders();
    getWarehouses();
    getDrawers();
    getClient();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, props.order_id, user.username]);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    setTimeout(() => {
      history.push("/accounting/invoice");
    }, 1200);

    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  const calculateTotal = (orderItems) => {
    return orderItems.reduce((total, item) => {
      return (
        total +
        (item.item_type === "Product"
          ? parseFloat(item.product_selling) * item.quantity
          : parseFloat(item.service_selling) * item.quantity)
      );
    }, 0);
  };

  const total = calculateTotal(cat);

  const submitData = {
    Action: "Post",
    order: props.order_id,
    total: totalWithoutDiscount,
    discount: discount,
    warehouse_id: selectedWarehouse,
    drawer_id: selectedDrawer,
    sub_id: username,
    userdealer: user.username,
    products: cat
      .filter((item) => item.item_type === "Product")
      .map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
      })),
  };
  const userOptions = users.map((item) => ({
    label: item.username,
    value: item.subscription_id,
  }));
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(`${API_URL}/inventory/invoices/`, submitData, {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    })
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success");
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger");
      });
  };

  let handleDiscountChange = (e) => {
    const discountValue = parseFloat(e.target.value);
    if (!isNaN(discountValue) && discountValue >= 0 && discountValue <= total) {
      setDiscount(discountValue);
      setTotalWithDiscount(total - discountValue);
    }
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <Select
              id="username"
              className="select-input"
              placeholder="Choose Username"
              options={userOptions}
              onChange={(opt) => setUsername(opt.value)}
              value={userOptions.find((option) => option.value === username)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="warehouse">Select Warehouse:</label>
            <select
              id="warehouse"
              className="form-control"
              value={selectedWarehouse}
              onChange={(e) => setSelectedWarehouse(e.target.value)}
              required
            >
              <option value="">Select Warehouse</option>
              {warehouses.map((warehouse) => (
                <option
                  key={warehouse.warehouse_id}
                  value={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="drawer">Select Drawer:</label>
            <select
              id="drawer"
              className="form-control"
              value={selectedDrawer}
              onChange={(e) => setSelectedDrawer(e.target.value)}
            >
              <option value="">Select Drawer</option>
              {Drawers.map((drawerss) => (
                <option key={drawerss.drawer_id} value={drawerss.drawer_id}>
                  {drawerss.drawer_name}
                </option>
              ))}
            </select>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Item Type</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {cat.map((item, index) => (
                <tr key={index}>
                  <td>{item.item_type}</td>
                  <td>
                    {item.item_type === "Product"
                      ? item.product_name
                      : item.service_name}
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {item.item_type === "Product"
                      ? `$${parseFloat(item.product_selling).toFixed(2)}`
                      : `$${parseFloat(item.service_selling).toFixed(2)}`}
                  </td>
                  <td>
                    {item.item_type === "Product"
                      ? `$${(
                          parseFloat(item.product_selling) * item.quantity
                        ).toFixed(2)}`
                      : `$${(
                          parseFloat(item.service_selling) * item.quantity
                        ).toFixed(2)}`}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" className="text-right">
                  Total:
                </td>
                <td>{`$${total.toFixed(2)}`}</td>
              </tr>
              {discount > 0 && (
                <tr>
                  <td colSpan="4" className="text-right">
                    Total without Discount:
                  </td>
                  <td>{`$${totalWithoutDiscount.toFixed(2)}`}</td>
                </tr>
              )}
              <tr>
                <td colSpan="4" className="text-right">
                  Discount:
                </td>
                <td>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max={total.toFixed(2)}
                    value={discount}
                    onChange={handleDiscountChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">
                  Total with Discount:
                </td>
                <td>{`$${totalWithDiscount.toFixed(2)}`}</td>
              </tr>
            </tbody>
          </table>

          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Create Invoice
            </button>
          </div>
        </form>
      </div>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default InvoiceOrder;
