import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import { useHistory } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import AuthContext from "../context/AuthContext";
import Formsa from "./editsub";
import Ping from "./ping";
import Cards from "./labels";

import ConsumptionHistory from "./consumptionHistory";
import Issues from "./issues";
import IssuesHistory from "./issuesHistory";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ProgressBar from "./progress.js";
import "./Internet.css";

function SubInternet(props) {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // To capture search input
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null); // For delayed search filter

  const [showo, setShowo] = useState(false);
  const [password, setpassword] = useState(0);
  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [showPing, setShowPing] = useState(false);
  const handleshowPing = () => setShowPing(true);
  const handleClosePing = () => setShowPing(false);

  const [showIssues, setShowIssues] = useState(false);
  const handleshowIssues = () => setShowIssues(true);
  const handleCloseIssues = () => setShowIssues(false);

  const [showHistory, setShowHistory] = useState(false);
  const handleshowHistory = () => setShowHistory(true);
  const handleCloseHistory = () => setShowHistory(false);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const handleUpdateAct = (id) => setSelectedSubscriptionId(id);
  const history = useHistory();
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    username: "",
    password: 0,
    subId: 0,
    intSubId: 0,
    intPlanId: 0,
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    consumption: "",
    connectivity: "",
    due: "",
  });

  const updateSubscriptionDetails = (details) => {
    setSubscriptionDetails(details);
  };
  const issues = (details) => {
    setSubscriptionDetails(details);
    history.push("/issues", { myProp: details.subId });
  };

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/radius/subscriptions/${props.url}`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();

    // Set up a timer to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 6000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
      cellClassName: (params) => getStatusCellStyle(params), // Apply cell style based on status
    },
    { field: "username", headerName: "Username", minWidth: 100 },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    { field: "suffix", headerName: "Suffix", flex: 1, minWidth: 70 },

    {
      field: "plan_name",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let max = params.row.daily_limit;
        let download = params.row.pppoe_download;
        let upload = params.row.pppoe_upload;

        if (params.row.plan_name.includes("STARTER")) {
          max = params.row.monthly_limit;
          download = params.row.monthly_download;
          upload = params.row.monthly_upload;
        }

        return (
          <ProgressBar
            planName={params.row.plan_name}
            max={max}
            download={download}
            upload={upload}
          />
        );
      },
    },
    {
      field: "labels",
      headerName: "Labels",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Cards sub_id={params.row.subscription_id} />;
      },
    },
    { field: "ip_address", headerName: "IP Address", flex: 1, minWidth: 120 },
    { field: "site", headerName: "Site/Core", flex: 1, minWidth: 85 },
    {
      field: "subscription_secret",
      headerName: "Token",
      flex: 1,
      minWidth: 95,
    },
    { field: "dealer_en", headerName: "Reseller", flex: 1, minWidth: 80 },
    {
      field: "connectivity",
      headerName: "connectivity",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "consumption_option",
      headerName: "Option",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "points",
      headerName: "Omegas",
      flex: 1,
      minWidth: 70,
    },
    { field: "due", headerName: "Due", flex: 1, minWidth: 50 },
    {
      field: "payment_date",
      headerName: "Payment",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        const dateString = params.value;
        // Parse the ISO 8601 format string
        const date = new Date(dateString);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        // Format the date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "expiration_date",
      headerName: "Expiry",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        const dateString = params.value;
        // Parse the ISO 8601 format string
        const date = new Date(dateString);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        // Format the date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "creation_date",
      headerName: "Creation",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        const dateString = params.value;
        // Parse the ISO 8601 format string
        const date = new Date(dateString);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        // Format the date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      minWidth: 160,
      renderCell: (params) => (
        <div>
          <div
            onClick={() => handleUpdateAct(params.row.subscription_id)}
          ></div>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                password: params.row.password,
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
                intPlanId: params.row.internet_plan_id,
                site: params.row.site,
                planName: params.row.plan_name,
                fullName: params.row.fullname_en,
                expDate: params.row.expiration_date,
                consumption: params.row.consumption_option,
                connectivity: params.row.connectivity,
                due: params.row.due,
              };
              updateSubscriptionDetails(details);
              handleshowo();
            }}
            className="edit-client"
          >
            Edit
          </button>
          <button
            onClick={() => {
              const details = {
                ip: params.row.ip_address,
                nas: params.row.nas_ip_address,
              };
              updateSubscriptionDetails(details);
              handleshowPing();
            }}
            className="edit-client"
          >
            Ping
          </button>
          <button
            onClick={() => {
              const details = {
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
              };
              updateSubscriptionDetails(details);
              issues(details);
            }}
            className="edit-client"
          >
            History
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  // Create a delayed search filter function
  const delaySearchFilter = (input) => {
    setSearchQuery(input);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchInput(query);

    // Clear the previous timeout if it exists
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }

    // Set a new timeout to trigger filtering after a delay
    const newTimeout = setTimeout(() => {
      delaySearchFilter(query);
    }, 100); // Adjust the delay as needed
    setSearchFilterTimeout(newTimeout);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleDeselectCheckboxes = () => {
    setCheckedRows([]);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="InternetTable ">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={handleDeselectCheckboxes}
          fetch={fetchData}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            rowsPerPage={10} // Set the default number of rows per page to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Formsa
            su={subscriptionDetails.subId}
            intsu={subscriptionDetails.intSubId}
            intpa={subscriptionDetails.intPlanId}
            usr={subscriptionDetails.username}
            full={subscriptionDetails.fullName}
            sit={subscriptionDetails.site}
            con={subscriptionDetails.connectivity}
            opti={subscriptionDetails.consumption}
            plana={subscriptionDetails.planName}
            exp={subscriptionDetails.expDate}
            pass={subscriptionDetails.password}
            due={subscriptionDetails.due}
            closeModal={handleedit}
            fetch={fetchData}
          />
        </div>
      </Modal>
      <Modal show={showPing} onHide={handleClosePing}>
        <Modal.Header closeButton>
          <Modal.Title>Ping</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Ping ip={subscriptionDetails.ip} nas={subscriptionDetails.nas} />
        </div>
      </Modal>
    </div>
  );
}

export default SubInternet;
