import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";

function DrawerTransfer() {
  const { user } = useContext(AuthContext);
  const [amountUSD, setAmountUSD] = useState(0);
  const [amountLBP, setAmountLBP] = useState(0);
  const [toDrawer, setToDrawer] = useState("");
  const [drawers, setDrawers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [viewMode, setViewMode] = useState("receipts"); // payments or receipts

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/exchange/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setDrawers(response.data);
        }
      } catch (error) {
        console.error("Error fetching drawers:", error);
        setAlertMessage("Failed to fetch drawer data.");
        setAlertType("danger");
        setShowAlert(true);
      }
    };

    fetchData();

    const fetchPayments = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/inventory/drawer/exchange/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
              action: "Payment",
            },
          }
        );

        if (response.status === 200) {
          setPayments(response.data);
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
        setAlertMessage("Failed to fetch payments.");
        setAlertType("danger");
        setShowAlert(true);
      }
    };

    const fetchReceipts = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/inventory/drawer/exchange/info/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
              action: "Receipt",
            },
          }
        );

        if (response.status === 200) {
          setReceipts(response.data);
        }
      } catch (error) {
        console.error("Error fetching receipts:", error);
        setAlertMessage("Failed to fetch receipts.");
        setAlertType("danger");
        setShowAlert(true);
      }
    };

    fetchPayments();
    fetchReceipts();
  }, [user.username]);

  useEffect(() => {
    calculateAmounts();
  }, [selectedPayments, selectedReceipts]);

  const calculateAmounts = () => {
    let totalUSD = 0;
    let totalLBP = 0;

    // Calculate total amounts for selected payments
    selectedPayments.forEach((id) => {
      const payment = payments.find((p) => p.payment_id === id);
      if (payment) {
        totalUSD -= parseFloat(payment.paid_amount_usd) || 0;
        totalLBP -= parseFloat(payment.paid_amount_lbp) || 0;
        totalLBP += parseFloat(payment.returned_lbp) || 0;
      }
    });

    // Calculate total amounts for selected receipts
    selectedReceipts.forEach((id) => {
      const receipt = receipts.find((r) => r.receipt_id === id);
      if (receipt) {
        totalUSD += parseFloat(receipt.paid_amount_usd) || 0;
        totalLBP += parseFloat(receipt.paid_amount_lbp) || 0;
        totalLBP -= parseFloat(receipt.returned_lbp) || 0;
      }
    });

    setAmountUSD(totalUSD);
    setAmountLBP(totalLBP);

    // Debugging logs
    console.log("Selected Payments:", selectedPayments);
    console.log("Selected Receipts:", selectedReceipts);
    console.log("Total USD:", totalUSD);
    console.log("Total LBP:", totalLBP);
  };

  const handleCheckboxChange = (type, id) => {
    if (type === "payment") {
      setSelectedPayments((prev) =>
        prev.includes(id) ? prev.filter((pid) => pid !== id) : [...prev, id]
      );
    } else if (type === "receipt") {
      setSelectedReceipts((prev) =>
        prev.includes(id) ? prev.filter((rid) => rid !== id) : [...prev, id]
      );
    }
  };

  const handleSelectAll = () => {
    const allPayments = payments.map((payment) => payment.payment_id);
    const allReceipts = receipts.map((receipt) => receipt.receipt_id);

    setSelectedPayments(allPayments);
    setSelectedReceipts(allReceipts);
  };

  const handleTransfer = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/inventory/exchange/`,
        {
          username: user.username,
          amountUSD: amountUSD,
          amountLBP: amountLBP,
          toDrawer: toDrawer,
          selectedPayments: selectedPayments,
          selectedReceipts: selectedReceipts,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        setAlertMessage("Transfer successful!");
        setAlertType("success");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage("Transfer failed. Please try again.");
      setAlertType("danger");
      setShowAlert(true);
      console.error("Error during transfer:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Drawer Transfer</h2>
      {showAlert && (
        <Alert
          variant={alertType}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {alertMessage}
        </Alert>
      )}

      <Form>
        <Form.Group as={Row} controlId="formViewMode">
          <Form.Label column sm="2">
            View Mode
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
            >
              <option value="payments">Payments</option>
              <option value="receipts">Receipts</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Button
          variant="secondary"
          onClick={handleSelectAll}
          style={{ margin: "10px" }}
        >
          Select All Payments & Receipts
        </Button>

        <div
          className="mt-4"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            height: "200px", // Static height for the scrollable area
            overflowY: "scroll",
          }}
        >
          {viewMode === "payments" ? (
            <>
              <h5>Payments</h5>
              {payments.map((payment) => (
                <div key={payment.payment_id}>
                  <Form.Check
                    type="checkbox"
                    checked={selectedPayments.includes(payment.payment_id)}
                    label={`Payment ID: ${payment.description} - Amount (USD): ${payment.paid_amount_usd}`}
                    onChange={() =>
                      handleCheckboxChange("payment", payment.payment_id)
                    }
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <h5>Receipts</h5>
              {receipts.map((receipt) => (
                <div key={receipt.receipt_id}>
                  <Form.Check
                    type="checkbox"
                    checked={selectedReceipts.includes(receipt.receipt_id)}
                    label={`${receipt.first_name} ${receipt.middle_name} ${receipt.last_name} - ${receipt.description} `}
                    onChange={() =>
                      handleCheckboxChange("receipt", receipt.receipt_id)
                    }
                  />
                </div>
              ))}
            </>
          )}
        </div>

        <Form.Group as={Row} controlId="formToDrawer" className="mt-4">
          <Form.Label column sm="2">
            To Drawer
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={toDrawer}
              onChange={(e) => setToDrawer(e.target.value)}
            >
              <option value="" disabled>
                Select a drawer
              </option>
              {drawers.map((drawer) => (
                <option key={drawer.drawer_id} value={drawer.drawer_id}>
                  {drawer.drawer_name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formAmountUSD">
          <Form.Label column sm="2">
            Amount (USD)
          </Form.Label>
          <Col sm="10">
            <Form.Control type="number" value={amountUSD} disabled />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formAmountLBP">
          <Form.Label column sm="2">
            Amount (LBP)
          </Form.Label>
          <Col sm="10">
            <Form.Control type="number" value={amountLBP} disabled />
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          onClick={handleTransfer}
          style={{ margin: "10px" }}
        >
          Transfer
        </Button>
      </Form>
    </div>
  );
}

export default DrawerTransfer;
